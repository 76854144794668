/** 
* Richmenu 課堂簽到
* 自動判斷當天上課班級，重新導向至課堂簽到頁面
*/

<template>
  {{ route.query.redirect }}
  <template v-if="!loading && todayCourseLessions.length > 1">
    <div class="liff-rollcall-redirect-page">
      <div class="page-title">您今天有 {{ todayCourseLessions.length }} 堂課，<br>請您選擇欲簽到的課堂：</div>

      <a-row>
        <a-col
          v-for="item in todayCourseLessions"
          :span="24"
        >
          <div class="page-card" @click="toRollcallPage(item.course.id)">
            <div class="card-header">
              <div class="header-title">{{ item.course.name }} - {{ item.lession.name }}</div>
              <div class="header-subtitle">{{ item.lession.start_date }} {{ item.lession.start_time }} 上課</div>
              <div class="header-subtitle" :style="{ color: '#FF8A00' }">
                {{ item.course.classroom }}
              </div>
            </div>

            <div class="card-actions">
              <a-button
                class="action-btn"
                type="primary"
                @click="toClassPage"
              >前往簽到</a-button>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </template>

  <template v-if="!loading && todayCourseLessions.length === 0">
    <div class="liff-rollcall-redirect-page">
      <div :style="{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '80vh' }">
        <div :style="{ textAlign: 'center', fontSize: '1.45rem', marginBottom: '28px' }">您今天沒有上課課堂 😆</div>
        <a-button :style="{ margin: '0 auto', fontSize: '1rem' }" @click="closeLiff">點我關閉頁面</a-button>
      </div>
    </div>
  </template>

  <template v-if="loading">
    <div :style="{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '90vh' }">
      <a-spin
        tip="載入中"
        :indicator="indicator"
      />
    </div>
  </template>
</template>

<script>
import { defineComponent, ref, onMounted, h } from 'vue'
import { LoadingOutlined } from '@ant-design/icons-vue';
import { useRoute, useRouter } from 'vue-router'
import liff from '@line/liff/dist/lib'
import moment from 'moment'
import api from '@/apis'
import RoleUtil from '@/utils/RoleUtil'
import DateUtil from '@/utils/DateUtil'

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();
    const router = useRouter();

    /* Liff */
    const context = ref({});
    const initLiff = (async () => {
      try {
        await liff.init({ liffId: process.env.VUE_APP_LIFF_MY_COURSES });
        context.value = liff.getContext();
        console.log(context.value);
      } catch (err) {
        console.log(err.code, err.message);
      }
    });

    const closeLiff = () => {
      liff.closeWindow();
    }

    /* Loading */
    const loading = ref(true);

    /* Data */
    const userId = ref('');
    const coursesData = ref([]);
    const todayCourseLessions = ref([]);

    /* Algorithm */
    const getTodayCourseLessions = () => {
      // filter today course & lession
      for (let i=0; i<coursesData.value.length; i++) {
        const course = coursesData.value[i];
        console.log(course);

        if (course.lessions.length == 0) return

        if (moment().isAfter(`${course.lessions[course.lessions.length-1].start_date} 23:59:59`)) continue
        
        for (let j=0; j<course.lessions.length; j++) {
          const lession = course.lessions[j];

          if (lession.start_date === DateUtil.currentDate()) {
            const data = {
              course: course,
              lession: lession
            }
            todayCourseLessions.value.push(data);
            break
          }
        }
      }

      // if just have one lession today, automatically redirect to rollcall page.
      // else if today lessions is greater than one lessions, let user choose which lession will take a rollcall.
      if (todayCourseLessions.value.length === 1) {
        toRollcallPage(todayCourseLessions.value[0].course.id);
      }

      console.log(todayCourseLessions.value);
    }

    /* Api */
    const getUserId = (async () => {
      let line_id;
      if (process.env.VUE_APP_ENV == 'production') {
        line_id = context.value.userId;
      } else if (process.env.VUE_APP_ENV == 'development') {
        line_id = process.env.VUE_APP_DEV_LINE_ID;
      }

      const response = await api.v1.line.parserLineId({
        line_id: line_id
      });
      userId.value = response.data.result.uid;
    });

    const getUserCoursesData = (async () => {
      try {
        const response = await api.v1.course.belongList(userId.value, {
          role: RoleUtil.MEMBER
        });
        coursesData.value = response.data.result;
      } catch (err) {
        console.log(err);
      }
    });

    /* Mounted */
    onMounted(async () => {
      await initLiff();
      await getUserId();

      // if query.redirect is my-courses, redirect to my-courses page
      // if query.redirect is rollcall, check the course and redirect to rollcall page
      if (route.query.redirect === 'myCourses') {
        console.log('redirect to my courses');
        router.push({ name: 'LiffMyCourses' });
      }
      else if (route.query.redirect ==='rollcall') {
        console.log('redirect to rollcall');
        await getUserCoursesData();
        getTodayCourseLessions();
      }

      loading.value = false;
    });

    /* Router */
    const toRollcallPage = (courseId) => {
      router.push({
        name: 'LiffRollcallPage',
        params: {
          course_id: courseId
        },
        query: {
          mode: 'lession_pre'
        }
      });
    }

    /* Loading Style */
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '2rem',
        marginBottom: '20px'
      },
      spin: true,
    });

    return {
      /* Liff */
      closeLiff,

      /* Loading */
      loading,
      indicator,

      /* Data */
      todayCourseLessions,

      /* Router */
      toRollcallPage,

      /* Utils */
      DateUtil
    }
  },
  methods: {}
})
</script>

<style lang="scss">
.liff-rollcall-redirect-page {
  background: #e2edf3;
  min-height: 100vh;
  padding: 20px;

  .page-title {
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 20px;
  }

  .page-card {
    background: white;
    border-radius: 4px;
    padding: 25px;
    margin-bottom: 20px;

    .card-header {
      margin-bottom: 12px;
      
      .header-title {
        color: #212121;
        font-weight: 500;
        font-size: 1.45rem;
      }

      .header-subtitle {
        color: #424242;
        font-size: 1.2rem;
      }
    }

    .card-content {
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 12px;
    }

    .card-img {
      max-height: 160px;
    }

    .card-actions {
      padding: 12px 0;
      
      .action-btn {
        width: 100%;
        font-size: 1.2rem;
        height: 48px;
      }
    }
  }
}
</style>